import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Box from "@material-ui/core/Box"
import CTAButton from "../components/CTAButton"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import classNames from "classnames"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Link as GatsbyLink } from "gatsby"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { default as React } from "react"
import dotsSquareImg from "../img/dots-square.png"
import { colors } from "../theme"
import purpleAccentImg from "../img/purple-accent.png"

const AdapterLink = React.forwardRef((props, ref) => (
  <GatsbyLink innerRef={ref} {...props} />
))

const useStyles = makeStyles(theme => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardPaddingDesktop: {
    padding: theme.spacing(3),
  },
  cardPaddingMobile: {
    padding: `${theme.spacing(2)}px 0`,
  },
  cardContent: {
    flex: 1,
  },
  cardActions: {
    flex: "0 0 auto",
  },
  borderLeftRadius: {
    borderTopLeftRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),
  },
  borderBottomLeftRadius: {
    borderBottomLeftRadius: 24,
  },
  signupIcon: {
    padding: "5px 10px",
    display: "inline-block",
    backgroundImage: `url(${purpleAccentImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "37px 34px",
    backgroundPosition: "right 0px bottom 0px",
  },
}))

const SignUp = ({ data: { signups } }) => {
  const classes = useStyles()
  const theme = useTheme()

  const md = useMediaQuery(theme.breakpoints.up("md"))
  const lg = useMediaQuery(theme.breakpoints.up("lg"))

  return (
    <Box py={8} mb={3} position="relative">
      {md && (
        <Box
          className={classes.borderLeftRadius}
          bgcolor={colors.grayBackground}
          position="absolute"
          height="100%"
          width={"40%"}
          top={0}
          right={0}
          zIndex={-1}
        />
      )}
      <Container maxWidth="lg">
        <Box pb={9}>
          <Box position="relative">
            <Box
              position="absolute"
              height={190}
              width={190}
              zIndex={-1}
              top={-theme.spacing(2)}
              right={-theme.spacing(lg ? 5 : md ? 3 : 1)}
              style={{
                backgroundImage: `url(${dotsSquareImg})`,
                backgroundRepeat: "no-repeat",
              }}
            />
            {md && (
              <Box
                className={classes.borderBottomLeftRadius}
                bgcolor={colors.grayMid}
                position="absolute"
                height={228}
                width={254}
                zIndex={-1}
                bottom={-theme.spacing(1)}
                left={-theme.spacing(3)}
              />
            )}
            {signups && !!signups.length && (
              <Grid container spacing={md ? 6 : 3} justify="center">
                {signups.map((signup, index) => (
                  <Grid key={index} item xs={12} sm={6}>
                    <Card
                      className={classNames(classes.card, {
                        [classes.cardPaddingDesktop]: md,
                        [classes.cardPaddingMobile]: !md,
                      })}
                    >
                      <CardContent className={classes.cardContent}>
                        <div className={classes.signupIcon}>
                          <FontAwesomeIcon
                            icon={{
                              prefix: "fal",
                              iconName: signup.icon,
                            }}
                            size="4x"
                          />
                        </div>
                        <Box color={colors.formTitle}>
                          <Typography variant="h3" color="inherit" gutterBottom>
                            {signup.title}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          gutterBottom
                        >
                          {signup.description}
                        </Typography>
                      </CardContent>
                      <CardActions className={classes.cardActions}>
                        {signup.button && (
                          <CTAButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            component={AdapterLink}
                            to={`/${signup.button.link}`}
                          >
                            {signup.button.content}
                          </CTAButton>
                        )}
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default SignUp
