import { graphql } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import SignUp from "../components/sign-up"
import Page from "../components/page"
import { buildMeta } from "../helpers"

const SignUpPage = ({
  data: { footerData, signUpPageData, siteData },
  location,
}) => {
  const { seo } = signUpPageData.frontmatter
  const { siteUrl } = siteData.siteMetadata

  return (
    <React.Fragment>
      <Helmet
        title={seo.title}
        meta={[
          ...buildMeta(siteData.siteMetadata, seo, null, location.pathname),
          ...(seo.metatags ? seo.metatags : []),
        ]}
      >
        <link rel="canonical" href={`${siteUrl}${location.pathname}`} />
        {seo.schemamarkup && (
          <script type="application/ld+json">{seo.schemamarkup}</script>
        )}
      </Helmet>
      <Page headerData={{ frontmatter: {} }} footerData={footerData}>
        <SignUp data={signUpPageData.frontmatter} />
      </Page>
    </React.Fragment>
  )
}

export default SignUpPage

export const pageQuery = graphql`
  query SignUpQuery {
    ...PageFragment
    siteData: site {
      siteMetadata {
        siteUrl
        env
      }
    }
    signUpPageData: markdownRemark(
      fileAbsolutePath: { regex: "/pages/sign-up.md/" }
    ) {
      frontmatter {
        seo {
          title
          description
          keywords
          schemamarkup
          metatags {
            name
            content
          }
        }
        signups {
          title
          description
          icon
          button {
            content
            link
          }
        }
      }
    }
  }
`
