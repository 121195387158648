export const buildMeta = (siteMetadata, seo, featuredimage, link = "") => {
  const meta = []

  if (seo.title) {
    meta.push({
      property: "og:title",
      content: seo.title,
    })

    meta.push({
      name: "twitter:title",
      content: seo.title,
    })
  }

  if (seo.description) {
    meta.push({
      name: "description",
      content: seo.description,
    })

    meta.push({
      property: "og:description",
      content: seo.description,
    })

    meta.push({
      name: "twitter:description",
      content: seo.description,
    })
  }

  if (seo.keywords) {
    meta.push({
      name: "keywords",
      content: seo.keywords.join(", "),
    })
  }

  meta.push({
    property: "og:url",
    content: `${siteMetadata.siteUrl}${link}`,
  })

  if (featuredimage) {
    if (featuredimage.source) {
      meta.push({
        property: "og:image",
        content: `${siteMetadata.siteUrl}${featuredimage.source.childImageSharp.fluid.src}`,
      })

      meta.push({
        name: "twitter:card",
        content: "summary_large_image",
      })

      meta.push({
        property: "twitter:image",
        content: `${siteMetadata.siteUrl}${featuredimage.source.childImageSharp.fluid.src}`,
      })
    }

    if (featuredimage.alt) {
      meta.push({
        property: "twitter:image:alt",
        content: featuredimage.alt,
      })
    }
  }

  if (siteMetadata.env === "production") {
    meta.push({
      name: "google-site-verification",
      content: "XLGk9rysxczyfD7nI2u2OHAVrf3LUiwBcRNLxR7or2o",
    })

    meta.push({
      name: "msvalidate.01",
      content: "2B19544AA1F850863828F0BDFE4082CA",
    })
  }

  return meta
}

export const formatError = error => {
  let errorMessage

  if (typeof error === "string") {
    errorMessage = error
  } else if (error.response) {
    const errorResponseData = error.response.data
    errorMessage = Array.isArray(errorResponseData)
      ? errorResponseData[0]
      : errorResponseData[Object.keys(errorResponseData)[0]]
  } else if (error.message) {
    errorMessage = error.message
  } else {
    errorMessage = "Something went wrong. Please try again."
  }

  return errorMessage
}
