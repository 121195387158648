import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
import { colors } from "../theme"

const borderWidth = 2

const containedSmallVerticalPadding = 4
const containedSmallHorizontalPadding = 10

const containedLargeVerticalPadding = 8
const containedLargeHorizontalPadding = 22

const CTAButton = withStyles({
  root: {
    fontSize: 16,
    fontWeight: "bold",
    "&:focused": {
      backgroundColor: colors.greenHover,
    },
    "&:active": {
      backgroundColor: colors.greenActive,
      borderColor: colors.greenActive,
    },
  },
  outlined: {
    backgroundColor: colors.white,
    color: colors.cta,
    border: `${borderWidth}px solid ${colors.cta}`,
    "&:hover": {
      backgroundColor: "#e8fbf3",
      color: colors.cta,
      border: `${borderWidth}px solid ${colors.cta}`,
    },
  },
  contained: {
    backgroundColor: colors.cta,
    color: colors.white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: colors.greenHover,
      color: colors.white,
    },
  },
  outlinedSizeSmall: {
    padding: `${containedSmallVerticalPadding -
      borderWidth}px ${containedSmallHorizontalPadding - borderWidth}px`,
  },

  outlinedSizeLarge: {
    padding: `${containedLargeVerticalPadding -
      borderWidth}px ${containedLargeHorizontalPadding - borderWidth}px`,
  },

  containedSizeSmall: {
    padding: `${containedSmallVerticalPadding}px ${containedSmallHorizontalPadding}px`,
  },

  containedSizeLarge: {
    padding: `${containedLargeVerticalPadding}px ${containedLargeHorizontalPadding}px`,
  },
})(Button)

export default CTAButton
